import { List, Map, Set, fromJS } from 'immutable';
import * as Sentry from '@sentry/browser';

import { defaultApplicationState } from 'Reducer';
import { setProfileProperties } from 'containers';
import { templateToNode } from './Tree';
import { sendWS } from './Sync';
import { defaultState as PROACTDefaultState } from './PROACT';
import { defaultState as FiveWhysDefaultState } from './FiveWhys';
import { defaultState as FishboneDefaultState } from './Fishbone';
import { applyChain, patchReducer } from './Patch';
import { signOutAndRedirect } from './Sync';
import { bootIntercomIo } from './intercom';

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

// @NOTE using this started freezing production for customers with tens of thousands of facilities
// function getFacilityUuidMap(facilities) {
//   const getNestedObjects = facility => {
//     const facilityCopy = { ...facility };

//     delete facilityCopy.sites;
//     delete facilityCopy.departments;

//     if ((!facility.sites || facility.sites.length < 1) && (!facility.departments || facility.departments.length < 1)) {
//       return facilityCopy;
//     }

//     if (facility.sites) {
//       return [facilityCopy, flatMapDeep(facility.sites, getNestedObjects)];
//     } else if (facility.departments) {
//       return [facilityCopy, flatMapDeep(facility.departments, getNestedObjects)];
//     }
//   };

//   const combined = flatMapDeep(facilities, getNestedObjects);
//   const uuidMap = combined.reduce((acc, obj) => {
//     const result = {
//       ...acc,
//       [`${obj.facilityUuid}`]: obj,
//     };

//     return result;
//   }, {});

//   return uuidMap;
// }

// function getEquipmentUuidMap(equipments) {
//   const getNestedObjects = equipment => {
//     const equipmentCopy = { ...equipment };

//     delete equipmentCopy.codes;
//     delete equipmentCopy.classes;

//     if ((!equipment.codes || equipment.codes.length < 1) && (!equipment.classes || equipment.classes.length < 1)) {
//       return equipmentCopy;
//     }

//     if (equipment.codes) {
//       return [equipmentCopy, flatMapDeep(equipment.codes, getNestedObjects)];
//     } else if (equipment.classes) {
//       return [equipmentCopy, flatMapDeep(equipment.classes, getNestedObjects)];
//     }
//   };

//   const combined = flatMapDeep(equipments, getNestedObjects);
//   const uuidMap = combined.reduce((acc, obj) => {
//     const result = {
//       ...acc,
//       [`${obj.equipmentUuid}`]: obj,
//     };

//     return result;
//   }, {});

//   return uuidMap;
// }

export const modeToDefaultState = mode => {
  switch (mode) {
    case '5WHYS':
      return FiveWhysDefaultState();
    case 'FISHBONE':
      return FishboneDefaultState();
    default:
      return PROACTDefaultState();
  }
};

const updateUserRole = (state, users, roles) => {
  if (roles.get(users.first().get('username'))) {
    let newState = state.setIn(
      ['users', users.first().get('username'), 'roles'],
      Set(roles.get(users.first().get('username')).map(x => x.get('role'))),
    );
    let nextUser = users.slice(1, users.size);
    if (nextUser.isEmpty()) {
      return newState;
    } else {
      newState = updateUserRole(newState, nextUser, roles);
      return newState;
    }
  } else {
    let nextUser = users.slice(1, users.size);
    if (nextUser.isEmpty()) {
      return state;
    } else {
      state = updateUserRole(state, nextUser, roles);
      return state;
    }
  }
};

export const showToast = (state, message, style) => state.set('toast', Map({ message, style }));

export const wsReducer = (state, message) => {
  const type = message.get('type');

  if (state.get('debug')) {
    console.groupCollapsed(`${type} (WSReducer)`);
    console.info('state', state.toJS ? state.toJS() : state);
    console.info('action', message.toJS ? message.toJS() : message);
    console.groupEnd();
  }

  switch (type) {
    case 'SUCCESS':
      return state;

    case 'AUTOCOMPLETE':
      return state.setIn(['selected', 'results'], message.get('results'));

    case 'PATCH': {
      const chain = message.get('chain');
      return state.updateIn(['tree', 'elements'], root => applyChain(root, chain));
    }

    case 'UPDATE_GROUP': {
      const groupUuid = message.getIn(['group', 'groupUuid']);
      const groupIndex = state.get('groups').findIndex(g => g.get('groupUuid') === groupUuid);

      const updates = state.updateIn(['groups', groupIndex], group => group.merge(message.get('group')));

      return showToast(updates, `Successfully updated "${message.get('group').get('name')}"`, 'SUCCESS');
    }

    case 'LIST_GROUPS': {
      const existingGroups = state.get('groups', List());
      const sortedGroups = message.get('groups').sort((a, b) => {
        return a.get('name').localeCompare(b.get('name'));
      });
      const withMemberObjects = sortedGroups.map(g => {
        const idx = existingGroups.findIndex(e => e.get('groupUuid') === g.get('groupUuid'));
        if (idx > -1) {
          return g.set('member-objects', existingGroups.getIn([idx, 'member-objects']));
        }
        return g;
      });

      return state.set('groups', withMemberObjects);
    }

    case 'DELETE_GROUP': {
      const groupUuid = message.getIn(['group', 'groupUuid']);
      const groupIndex = state.get('groups').findIndex(g => g.get('groupUuid') === groupUuid);

      const updates = state.removeIn(['groups', groupIndex]);

      return showToast(updates, 'Successfully deleted group', 'SUCCESS');
    }

    case 'ADD_GROUP': {
      const updates = state.merge({
        groups: state.get('groups', List()).push(message.get('group')),
      });

      return showToast(updates, `Successfully created "${message.get('group').get('name')}"`, 'SUCCESS');
    }

    case 'GET_GROUP_MEMBERS': {
      const members = message.get('members').map(m => {
        return Map({
          username: m.get('username'),
          fullName: m.get('fullName'),
        });
      });

      const updates = state.updateIn(['groups'], groups => {
        const idx = groups.findIndex(g => g.get('groupUuid') === message.get('groupUuid'));
        const withMemberObjects = groups.setIn([idx, 'member-objects'], members);

        return withMemberObjects;
      });

      return updates;
    }

    case 'ADD_USER_TO_GROUP': {
      const groupUuid = message.getIn(['group', 'groupUuid']);
      const groupIndex = state.get('groups').findIndex(g => g.get('groupUuid') === groupUuid);
      const username = message.getIn(['group', 'username']);
      const memberObj = state.get('users').find(u => u.get('username') === username);
      const groupName = state.getIn(['groups', groupIndex]).get('name');

      const updates = state.updateIn(['groups', groupIndex], group => {
        const newMember = Map({
          admin: message.getIn(['group', 'admin'], false),
          fullName: memberObj.get('fullName'),
          username: memberObj.get('username'),
        });

        const updatedMemberUsernames = group.updateIn(['members'], List(), members => {
          return members.push(memberObj.get('username'));
        });
        const updatedMemberObjects = updatedMemberUsernames.updateIn(['member-objects'], List(), members =>
          members.push(newMember),
        );

        // group.get('members').push(memberObj.get('username'));
        // group.get('member-objects').push(newMember);

        return updatedMemberObjects;
      });

      return showToast(updates, `Added ${memberObj.get('username')} to ${groupName}`, 'SUCCESS');
    }

    case 'REMOVE_USER_FROM_GROUP': {
      const groupUuid = message.getIn(['group', 'groupUuid']);
      const username = message.getIn(['group', 'username']);

      const groupIndex = state.get('groups').findIndex(g => g.get('groupUuid') === groupUuid);

      const userObj = state.get('users').find(u => u.get('username') === username);
      const groupName = state.getIn(['groups', groupIndex]).get('name');

      const updatedGroupUsers = state.getIn(['groups', groupIndex, 'members']).filter(m => m !== username);
      const updatedGroupMemberObjects = state
        .getIn(['groups', groupIndex, 'member-objects'])
        .filter(m => m.get('username') !== username);

      const updates = state
        .setIn(['groups', groupIndex, 'members'], updatedGroupUsers)
        .setIn(['groups', groupIndex, 'member-objects'], updatedGroupMemberObjects);

      return showToast(updates, `Removed ${userObj.get('username')} from ${groupName}`, 'SUCCESS');
    }

    case 'SET_GROUP_ADMIN': {
      const groupUuid = message.getIn(['group', 'groupUuid']);
      const username = message.getIn(['group', 'username']);
      const isAdmin = message.getIn(['group', 'admin']);
      const groupIndex = state.get('groups').findIndex(g => g.get('groupUuid') === groupUuid);
      const adminIndex = state.getIn(['groups', groupIndex, 'admins']).findIndex(m => m === username);

      const updates = state.updateIn(['groups', groupIndex, 'admins'], admins => {
        if (isAdmin) {
          return admins.push(username);
        }

        return admins.remove(adminIndex);
      });
      return updates;
    }

    case 'FETCH_TEMPLATE': {
      const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
      const node = templateToNode(message.get('tree'));

      return patchReducer(state, Map({ type: 'ADD', path, node }));
    }

    case 'FETCH_TEMPLATE_LIST': {
      return state.set('templates', message.get('templateTitles'));
    }

    case 'FETCH_INTERNAL_TEMPLATE_PREVIEW': {
      const tree = state.getIn(['trees', message.get('treeUuid')]).merge(message.delete('type'));

      // const update = state.set('selectedTemplateUuid', message.get('templateUuid'));

      return state.set('selectedInternalTemplate', tree);
    }

    case 'FETCH_TEMPLATE_PREVIEW': {
      const selectedTemplate = message.get('tree');

      return state.set('selectedTemplate', selectedTemplate).delete('selectedInternalTemplate');
    }

    case 'FETCH_TEMPLATE_OPTIONS': {
      const options = message.get('options');

      return state.setIn(['tree', 'view', 'templateOptions'], options);
    }

    case 'SYNC': {
      const elements = message.get('elements');
      const edges = message.get('edges');
      const uuid = message.get('treeUuid');
      const orientation = message.get('orientation');

      const defaultState = modeToDefaultState(state.getIn(['trees', uuid, 'methodology'])).setIn(
        ['view', 'layout', 'rankDir'],
        orientation,
      );

      if (elements) {
        return state
          .set('tree', defaultState)
          .setIn(['tree', 'uuid'], uuid)
          .setIn(['tree', 'edges'], edges || List())
          .setIn(['tree', 'elements'], elements)
          .set('url', `/tree/${uuid}`);
      } else {
        return state
          .set('tree', defaultState)
          .setIn(['tree', 'uuid'], uuid)
          .setIn(['tree', 'edges'], edges || List())
          .set('url', `/tree/${uuid}`);
      }
    }

    case 'ORIENTATION': {
      const orientation = message.get('orientation');
      return state.setIn(['tree', 'view', 'layout', 'rankDir'], orientation);
    }

    // nop, keepalive for heroku
    case 'ONLINE':
      return state;

    case 'ERROR': {
      const error = message.get('error');
      return showToast(state, error, 'ERROR');
      return state;
    }

    case 'SHOW_TOAST': {
      const text = message.get('text');
      const style = message.get('style');
      return showToast(state, text, style);
      return state;
    }

    case 'LIST': {
      const currentTrees = state.get('trees', Map());
      const newTrees = Map(message.get('trees').map(x => List([x.get('treeUuid'), x])));
      const newKeys = Set(newTrees.keySeq());
      const currentKeys = Set(currentTrees.keySeq());
      const singleTree = !!message.get('treeUuid'); // LIST with a treeUuuid is always a single tree
      const deletedKeys = singleTree ? Set() : currentKeys.subtract(newKeys);

      const mergedTrees = deletedKeys.reduce((acc, k) => acc.delete(k), currentTrees.merge(newTrees));

      const updates = state.set(
        'trees',
        mergedTrees.map(v => v.update('members', m => Set(m))),
      );

      return updates;
    }

    case 'GET_MEMBERS': {
      const uuid = message.get('treeUuid');
      const members = message.get('members');

      return state.setIn(['trees', uuid, 'members'], Set(members));
    }

    case 'NEW_TREE': {
      const treeUuid = message.getIn(['tree', 'treeUuid']);
      const tree = message.get('tree').update('members', m => Set(m));
      const creator = message.get('creator');

      if (creator === state.get('username')) {
        sendWS(state.get('ws'), Map({ type: 'SYNC', treeUuid }));
        return state.setIn(['trees', treeUuid], tree);
      } else {
        sendWS(state.get('ws'), Map({ type: 'LIST' }));
        return showToast(
          state.setIn(['trees', treeUuid], tree),
          `Teammate ${creator} has shared you on a new tree`,
          'DEFAULT',
        );
      }
    }

    case 'UPLOAD_FILE': {
      const uploadId = message.get('uploadId');
      return state.updateIn(['uploads', uploadId], x => x.set('uploadAttributes', message.get('uploadAttributes')));
    }

    case 'DELETE_FILE': {
      const treeUuid = message.get('treeUuid');
      const fileUuid = message.get('fileUuid');

      return state.deleteIn(['files', treeUuid, fileUuid]);
    }

    case 'DOWNLOAD_FILE': {
      const url = message.get('url');
      const fileUuid = message.get('fileUuid');
      const treeUuid = message.get('treeUuid');
      const filename = state.getIn(['files', treeUuid, fileUuid, 'filename']);

      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const objectUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objectUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            a.remove();
            window.URL.revokeObjectURL(objectUrl);
          }, 1500);
        });
      return state;
    }

    case 'GET_FILE_METADATA': {
      const treeUuid = message.get('treeUuid');
      const files = Map(message.get('files').map(x => List([x.get('fileUuid'), x])));

      return state.setIn(['files', treeUuid], files);
    }

    case 'ADD_FILE': {
      const treeUuid = message.get('treeUuid');
      const file = message.get('file');
      const fileUuid = file.get('fileUuid');

      return state.setIn(['files', treeUuid, fileUuid], file);
    }

    case 'ADD_TASK': {
      const treeUuid = message.get('treeUuid');
      const analysisName = state.getIn(['trees', treeUuid, 'title']);

      sendWS(state.get('ws'), Map({ type: 'GET_TASKS', treeUuid }));

      return state;
    }

    case 'EDIT_TASK': {
      const treeUuid = message.get('treeUuid');

      sendWS(state.get('ws'), Map({ type: 'GET_TASKS', treeUuid }));

      return state;
    }

    case 'GET_TASKS': {
      const tasks = message.get('tasks');
      const treeUuid = message.get('treeUuid');

      const tasksMap = Map(tasks.map(t => List([t.get('taskUuid'), t])));

      return state.setIn(['tasks', treeUuid], tasksMap);
    }

    case 'DELETE_TASK': {
      const treeUuid = message.get('treeUuid');
      const taskUuid = message.get('taskUuid');

      return state.deleteIn(['tasks', treeUuid, taskUuid]);
    }

    case 'COMPLETE_TASK': {
      const treeUuid = message.get('treeUuid');
      const taskUuid = message.get('taskUuid');
      const completedAt = message.get('completedAt');

      return completedAt
        ? state.setIn(['tasks', treeUuid, taskUuid, 'completedAt'], completedAt)
        : state.deleteIn(['tasks', treeUuid, taskUuid, 'completedAt']);
    }

    case 'ADD_NOTE': {
      const treeUuid = message.get('treeUuid');
      const note = message.get('note');

      return state.setIn(['notes', treeUuid, note.get('noteUuid')], note);
    }

    case 'EDIT_NOTE': {
      const treeUuid = message.get('treeUuid');
      const noteUuid = message.get('noteUuid');
      const text = message.get('text');
      const type = message.get('noteType');

      return state
        .setIn(['notes', treeUuid, noteUuid, 'text'], text)
        .setIn(['notes', treeUuid, noteUuid, 'noteType'], type);
    }

    case 'GET_NOTES': {
      const treeUuid = message.get('treeUuid');

      const notes = Map(message.get('notes').map(x => List([x.get('noteUuid'), x])));

      return state.setIn(['notes', treeUuid], notes);
    }

    case 'DELETE_NOTE': {
      const treeUuid = message.get('treeUuid');
      const noteUuid = message.get('noteUuid');

      return state.deleteIn(['notes', treeUuid, noteUuid]);
    }

    case 'GET_REPORT_DATA': {
      const treeUuid = message.getIn(['report', 'tree', 'treeUuid']);
      // don't want to deal with immutable here, use vanilla object
      const updates = {
        ...state.get('reports', {}),
        [treeUuid]: message.get('report').toJS(),
      };

      return state.merge({ reports: updates });
    }

    case 'GET_SIGNATURE': {
      const tag = message.get('tag');
      const value = message.get('value');
      const sig = message.get('signature');

      switch (tag) {
        case 'reportLink': {
          copyToClipboard(`${window.location.origin}/${value}/${sig}`);
          return showToast(state, 'Your view-only link has been created and copied to your clipboard.', 'SUCCESS');
          break;
        }
        default:
          console.log('Unknown tag', tag);
      }
      return state;
    }

    case 'REPORT_READY': {
      const url = message.get('url');
      const elem = document.createElement('a');
      elem.href = url;
      elem.style.display = 'none';
      elem.click();
      elem.setAttribute('download', '');

      return showToast(state, 'Your report is ready.', 'SUCCESS');
    }

    case 'GET_USER': {
      const user = message.get('user');
      bootIntercomIo(user);
      const username = user.get('username');
      Sentry.setUser({
        email: username,
        username,
        organization: user.companyName,
        name: user.fullName,
      });
      return state.mergeIn(['users', username], user);
    }

    case 'GET_ORGANIZATION': {
      const organization = message.get('organization', List());
      const companyName = organization.first(Map()).get('companyName', '');
      const licenses = organization.first(Map()).get('licenses', 0);
      let user = organization.get(
        organization.findIndex(i => i.get('username').toLowerCase() === state.get('username').toLowerCase()),
      );

      // @NOTE have to handle mixpanel here because org and email are unavailable during GET_USER in Reducer
      setProfileProperties({
        Organization: companyName,
        $email: user.get('email'),
        $name: user.get('fullName', 'Unknown'),
      });

      // let customNames = Map({
      //   equipment: Map({
      //     type: user.get('customNames') ? user.get('customNames').get('equipment').get('type') : '',
      //     class: user.get('customNames') ? user.get('customNames').get('equipment').get('class') : '',
      //     code: user.get('customNames') ? user.get('customNames').get('equipment').get('code') : '',
      //     equipmentUuid: 'equipmentUuid',
      //   }),
      //   facility: Map({
      //     location: user.get('customNames') ? user.get('customNames').get('facility').get('location') : '',
      //     site: user.get('customNames') ? user.get('customNames').get('facility').get('site') : '',
      //     department: user.get('customNames') ? user.get('customNames').get('facility').get('department') : '',
      //     facilityUuid: 'facilityUuid',
      //   }),
      // });
      // user = user.set('customNames', customNames);

      const updates = state.set('organization', Map({ companyName, licenses, user }));

      const updatesWithUsers = updates.update('users', Map(), us =>
        organization.reduce((acc, u) => acc.mergeDeepIn([u.get('username')], u), us),
      );

      return updatesWithUsers;
    }

    case 'INVITE_EMAIL': {
      return showToast(state, 'You have sent an invitation to the user.', 'SUCCESS');
    }

    case 'RESEND_INVITE': {
      const email = message.get('email');
      return showToast(state, `Invitation to ${email} has been re-sent.`, 'SUCCESS');
    }

    case 'RESET_MEMBER': {
      return showToast(state, 'You have sent the user a password reset email.', 'SUCCESS');
    }

    case 'DEMOTE_MEMBER': {
      const demoter = message.get('demoter');
      const demotee = message.get('demotee');
      const me = state.get('username');
      const newState = state.updateIn(['users', demotee, 'roles'], Set(), u => u.delete('ADMIN'));

      if (me === demotee) {
        return showToast(newState, `${demoter} has demoted you to a regular user account.`, 'WARNING');
      }
      return showToast(newState, 'You have successfully demoted this user back to a regular account.', 'SUCCESS');
    }

    case 'PROMOTE_MEMBER': {
      const promoter = message.get('promoter');
      const promotee = message.get('promotee');
      const me = state.get('username');
      const newState = state.updateIn(['users', promotee, 'roles'], Set(), u => u.add('ADMIN'));

      if (promotee === me) {
        return showToast(newState, `${promoter} has successfully promoted you to Admin.`, 'SUCCESS');
      }

      return showToast(newState, 'You have successfully promoted the user to Admin.', 'SUCCESS');
    }

    case 'REVOKE_MEMBER': {
      const revokee = message.get('revokee');
      if (revokee === state.get('username')) {
        signOutAndRedirect(state.get('ws'), null);
      }
      return showToast(
        state.updateIn(['users', revokee, 'roles'], Set(), u => u.delete('PAID')),
        'You have successfully revoked access.',
        'SUCCESS',
      );
    }

    case 'ASSIGN_MEMBER': {
      const assignee = message.get('assignee');
      const assigner = message.get('assigner');
      const me = state.get('username');
      const newState = state.updateIn(['users', assignee, 'roles'], Set(), u => u.add('PAID'));

      if (assignee === me) {
        return showToast(newState, `You have received a license to EasyRCA from ${assigner}.`, 'SUCCESS');
      }

      return showToast(newState, `You have successfully granted a new license to ${assignee}.`, 'SUCCESS');
    }

    case 'PRESENCE': {
      const members = message.get('members');
      return state.setIn(['tree', 'view', 'members'], Set(members));
    }

    case 'SET_VIEWER_ACL': {
      const treeUuid = message.get('treeUuid');
      const canView = message.get('canView');
      return state.setIn(['trees', treeUuid, 'canView'], canView);
    }

    // case 'SET_TREE_PUBLISHED': {
    //   const treeUuid = message.get('treeUuid');
    //   const isPublished = message.get('published');

    //   return state.setIn(['trees', treeUuid, 'published'], isPublished);
    // }

    case 'UPDATE_TREE': {
      const tree = message.get('tree').update('members', Set(), ms => Set(ms));
      const treeUuid = tree.get('treeUuid');
      const members = tree.get('members');
      const name = tree.get('name');
      const username = state.get('username');

      const newState = state.setIn(['trees', treeUuid], tree);

      if (members.has(state.get('username'))) {
        if (!Set(state.getIn(['trees', treeUuid, 'members'])).has(username)) {
          return showToast(newState, `You have been added to "${name}."`, 'SUCCESS');
        }
      }

      return newState;
    }

    case 'TREE_UPDATED': {
      // const { updates, treeUuid } = message;
      const updates = message.get('data');
      const treeUuid = message.get('treeUuid');
      const previousTree = state.getIn(['trees', treeUuid]);
      const merged = state.mergeIn(['trees', treeUuid], fromJS(updates));
      const username = state.get('username');
      const title = merged.get('title');

      // debugger;

      // console.info(`[BEFORE] Tree ${treeUuid}`, previousTree);
      // console.info(`[AFTER] Tree ${treeUuid}`, merged.getIn(['trees', treeUuid]));

      // update included changes to members of tree
      if ('members' in updates) {
        // debugger;
        //   // current user was added to tree in update
        if (!previousTree.getIn(['members', username]) && updates.members.includes(username)) {
          // debugger;
          return state.set('toast', Map({ message: `You have been added to ${title}.`, style: 'SUCCESS' }));
        }
      }

      return merged;
    }

    // case 'SET_TREE_OWNER': {
    //   return state;
    // }

    case 'ADD_MEMBER': {
      const adder = message.get('adder');
      sendWS(state.get('ws'), Map({ type: 'LIST' }));

      return showToast(state, `Teammate ${adder} has shared you on a new tree`, 'DEFAULT');
    }

    case 'DELETE_MEMBER': {
      const deletee = message.get('deletee');
      const treeUuid = message.get('treeUuid');
      const currentTreeUuid = state.getIn(['tree', 'uuid']);
      if (state.get('username') === deletee && currentTreeUuid === treeUuid) {
        // adios
        window.location.reload();
        return state;
      } else {
        sendWS(state.get('ws'), Map({ type: 'LIST' }));
        return state;
      }
    }

    case 'DELETE_TREE': {
      const currentTreeUuid = state.getIn(['tree', 'uuid']);
      const treeUuid = message.get('treeUuid');
      const deletedAt = message.get('deletedAt');
      const deleter = message.get('deleter');

      const newState = state.setIn(['trees', treeUuid, 'deletedAt'], deletedAt);

      if (currentTreeUuid === treeUuid) {
        return showToast(newState, `This tree has been deleted by ${deleter}.`, 'WARNING');
      }
      return newState;
    }

    case 'RESTORE_TREE': {
      const currentTreeUuid = state.getIn(['tree', 'uuid']);
      const treeUuid = message.get('treeUuid');
      const restorer = message.get('restorer');
      const newState = state.deleteIn(['trees', treeUuid, 'deletedAt']);

      if (currentTreeUuid === treeUuid) {
        return showToast(newState, `This tree has been moved to Active by ${restorer}.`, 'WARNING');
      }
      return newState;
    }

    case 'VIEWER_LINK': {
      const treeUUID = message.get('treeUUID');
      const viewerUUID = message.get('viewerUUID');
      const viewerLink = `${window.location.origin}/#/viewer/${viewerUUID}`;
      copyToClipboard(viewerLink);

      return showToast(
        state.setIn(['trees', treeUUID, 'viewerUUID'], viewerUUID),
        'Your view-only link has been created and copied to your clipboard.',
        'SUCCESS',
      );
    }

    case 'VIEWER_LINK_DELETE': {
      const treeUUID = message.get('treeUUID');

      return showToast(
        state.deleteIn(['trees', treeUUID, 'viewerUUID']),
        'Your view-only link has been disabled.',
        'SUCCESS',
      );
    }

    case 'GET_VIEWER_LINK': {
      const treeUUID = message.get('treeUUID');
      const viewerUUID = message.get('viewerUUID');

      if (viewerUUID) {
        return state.setIn(['trees', treeUUID, 'viewerUUID'], viewerUUID);
      } else {
        return state.deleteIn(['tree', treeUUID, 'viewerUUID']);
      }
    }

    case 'EDGE_ADD': {
      const edge = message.get('edge');
      return state.updateIn(['tree', 'edges'], List(), l => l.push(edge));
    }

    case 'EDGE_DELETE': {
      const edge = message.get('edge');
      return state.updateIn(['tree', 'edges'], List(), l => l.filterNot(x => x.equals(edge)));
    }

    case 'GET_ROLES': {
      const roles = message.get('roles');
      if (roles.isEmpty()) {
        return state;
      } else {
        const baseUpdates = state.setIn(
          ['users', roles.first().get('username'), 'roles'],
          Set(roles.map(x => x.get('role'))),
        );

        return baseUpdates.set('role', roles.map(x => x.get('role')).toJS());
      }
    }

    case 'GET_ROLES_USERS': {
      const roles = message.get('roles');
      if (!roles || (roles && roles.isEmpty())) {
        return state;
      } else {
        const users = state.get('users');
        const newRoles = roles.groupBy(x => x.get('username'));
        return updateUserRole(state, users, newRoles);
        // return state.set('users', users);
      }
    }

    case 'GET_FEED': {
      const feed = message.get('feed');

      return state.set('feed', feed);
    }

    case 'GET_TASKS_FEED': {
      const tasks = message.get('tasks');
      return state.set('finalTaskFeed', tasks);
    }

    case 'GET_NODES': {
      const nodes = message.get('nodes');
      return state.set('nodesFeed', nodes);
    }

    case 'SEARCH_NODES': {
      const searchResults = message.get('results');
      const matchedUuids = Map(searchResults.map(r => [r.getIn(['item', 'treeUuid']), r.getIn(['score'])]));

      return state.merge(
        Map({
          searchResults,
          searchResultsTreeUuids: matchedUuids,
        }),
      );
    }

    case 'GET_TIMELINE_EVENTS': {
      const treeUuid = message.get('treeUuid');
      const events = Map(message.get('events').map(e => List([e.get('eventUuid'), e])));

      return state.setIn(['events', treeUuid], events);
    }

    case 'SET_TIMELINE_EVENT':
    case 'ADD_TIMELINE_EVENT': {
      const treeUuid = message.get('treeUuid');
      const event = message.get('event');
      const eventUuid = message.getIn(['event', 'eventUuid']);

      return state.setIn(['events', treeUuid, eventUuid], event);
    }

    case 'EDIT_TIMELINE_EVENT': {
      const treeUuid = message.get('treeUuid');
      const event = message.get('event');
      const eventUuid = message.getIn(['event', 'eventUuid']);

      return state.setIn(['events', treeUuid, eventUuid], event);
    }

    case 'DELETE_TIMELINE_EVENT': {
      const treeUuid = message.get('treeUuid');
      const eventUuid = message.getIn(['event', 'eventUuid']);

      return state.deleteIn(['events', treeUuid, eventUuid]);
    }

    case 'SET_TREE_FAVORITE': {
      const treeUuid = message.get('treeUuid');
      const isFavorite = message.get('isFavorite');

      return state.setIn(['trees', treeUuid, 'isFavorite'], isFavorite);
    }

    // case 'REQUEST_TICKET': {
    //   const ticket_uuid = message.get('ticket_uuid');
    //   const file = state.get('fileToUpload');
    //   const uploadType = state.get('uploadType');
    //   const baseUrl = window.location.origin;
    //   const formData = new FormData();
    //   formData.append('ticket_uuid', ticket_uuid);
    //   formData.append('file', file);
    //   if (uploadType === 'facility') {
    //     uploadCSVFile(`${baseUrl}/organization/facility/upload`, formData, res => {
    //       if (res.success) {
    //         sendWS(state.get('ws'), Map({ type: 'GET_FACILITIES' }));
    //       }
    //     });
    //   } else {
    //     uploadCSVFile(`${baseUrl}/organization/equipment/upload`, formData, () => {
    //       // if (res.success) {
    //       //   sendWS(state.get('ws'), Map({ type: 'GET_EQUIPMENTS' }));
    //       // }
    //     });
    //   }
    //   return state.delete('fileToUpload').delete('uploadType');
    // }

    case 'EXPIRED_USER': {
      signOutAndRedirect(state.get('ws'), process.env.REACT_APP_EXPIRED_REDIRECT_URL);
      return state; // unreachable
    }

    case 'SIGN_OUT': {
      const url = message.get('logoutUrl');
      signOutAndRedirect(state.get('ws'), url);
      if (url) {
        // This is an SSO/M$ log in, we will be redirected to M$ and never return. If we
        // return defaultApplicationState other things will happen, which we do not want?
        return state;
      }
      // @note returning the initial state is important for properly clearing persisted state from auth
      return defaultApplicationState;
    }

    case 'GET_RCAS_CSV': {
      const csv = message.get('csv');
      const blob = new Blob([csv], { type: 'text/csv' });
      const objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = `EasyRCA_Analyses_${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        a.remove();
        window.URL.revokeObjectURL(objectUrl);
      }, 1500);
      return state;
    }

    default: {
      return showToast(state, `Unknown message type: ${type}`, 'ERROR');
    }
  }
};
