import { fromJS, Map } from 'immutable';
import { matchPath } from 'react-router';

// TODO: Come up with something better.
// This should match the golang/api/http.go:apiVersion constant.
// Changing it will ensure that the client side will reload.
// Make sure to change it in *both places* or it will infinitely reload.
const apiVersion = 20231207;

export const storeToken = (username, token) => {
  localStorage.setItem('token', JSON.stringify({ username, token }));
};

export const clearToken = () => {
  if (!window.location.toString().startsWith('http://localhost:')) {
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    localStorage.removeItem('easyrca-production');
  } else {
    // This is because constantly having to log in during development gets tired very quickly
    console.log('clearToken(): Not clearing the token because this appears to be a dev environment!');
  }
};

export const fetchToken = () => JSON.parse(localStorage.getItem('token'));

export const hasToken = () => !!fetchToken();

export const sendWS = (ws, msg) => {
  let jsObj = msg;

  try {
    jsObj = msg.toJS ? msg.toJS() : msg;
    const rawMsg = JSON.stringify(jsObj);
    // don't bother dealing with the payload if we can't send it
    if (ws && ws.readyState === 1) {
      ws.send(rawMsg);
    } else {
      console.error(`[sendWs]: websocket not ready, ignoring message:\n${jsObj}`);
    }
  } catch (error) {
    console.error('[sendWs]: failed to stringify/prepare payload', jsObj);
    console.error('[sendWs]: error', error);
  }
};

export const signOutAndRedirect = (ws, url) => {
  ws.close();
  // window.location.pathname = '/logout';
  window.location.replace(url ? url : '/#/logout');
  // clearToken();
};

// // const DUMMY_SEARCH_RESULTS = [];
// const DUMMY_SEARCH_RESULTS = [
//   {
//     item: {
//       treeUuid: '3cd40a50-58c2-4052-82da-4e66509f4502',
//     },
//     score: 1.2,
//   },
//   {
//     item: {
//       treeUuid: 'a9e5b77f-12d6-4f1b-a239-e1d4934190f4',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       templateUuid: 'e2f1268d-961f-4b06-81be-b7dcbdf702d5',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       treeUuid: '8008cfbd-3e78-47a1-b881-5bea12bf3c5e',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       treeUuid: '76a68e94-f7a4-43cc-a962-97fca1f10b9b',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       templateUuid: 'e902e303-c5bf-4534-a26b-5463059680e4',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       treeUuid: '94193fbe-c5f2-4388-96e8-ed07f63cec8f',
//     },
//     score: 1,
//   },
//   {
//     item: {
//       templateUuid: '852dbf39-f19f-424a-8769-6bce0439a7b0',
//     },
//     score: 1,
//   },
// ];

const encodeGetParams = p =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

export const getAvailableCustomFields = async ({ token }) => {
  if (!token) {
    throw new Error('No token provided. Cannot fetch custom fields without authorization.');
  }

  const results = await fetch('/api/v0/customFields', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to get custom fields failed critically.');
  }

  console.log(results);

  const json = await results.json();

  if (!json.ok) {
    console.error(`[getAvailableCustomFields, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const createNewTree = async ({ token, type }) => {
  if (!token) {
    throw new Error('No token provided. Cannot fetch custom fields without authorization.');
  } else if (!type) {
    throw new Error('No type (PROACT, Fishbone, 5 Whys) provided. Cannot create a tree without it.');
  }

  const results = await fetch('/api/v0/tree', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      methodology: type,
    }),
  });

  if (!results) {
    throw new Error('Failed to create new tree.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[createNewTree, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const addCustomFieldValueToTree = async ({ token, treeUuid, customFieldUuid, value }) => {
  if (!token) {
    throw new Error('No token provided. Cannot add value for custom field without authorization.');
  } else if (!treeUuid) {
    throw new Error('No tree uuid provided. Cannot add value for custom field without it.');
  } else if (!customFieldUuid) {
    throw new Error('No custom field uuid provided. Cannot add value for custom field without it.');
  }

  const results = await fetch(`/api/v0/tree/${treeUuid}/customValue/${customFieldUuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(value),
  });

  if (!results) {
    throw new Error('Request to add value for custom field failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[addCustomFieldValueToTree, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const getG2ReviewToken = async ({ token }) => {
  if (!token) {
    throw new Error('Token not provided.');
  }

  const results = await fetch('/api/v0/g2/state', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to retrieve G2 state token failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[getG2ReviewToken, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const removeCustomFieldValueFromTree = async ({ token, treeUuid, customFieldUuid }) => {
  if (!token) {
    throw new Error('No token provided. Cannot remove value for custom field without authorization.');
  } else if (!treeUuid) {
    throw new Error('No tree uuid provided. Cannot remove value for custom field without it.');
  } else if (!customFieldUuid) {
    throw new Error('No custom field uuid provided. Cannot remove value for custom field without it.');
  }

  const results = await fetch(`/api/v0/tree/${treeUuid}/customValue/${customFieldUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to remove custom field value failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[removeCustomFieldValueFromTree, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const downloadDashboardTasksCSV = async ({ token }) => {
  if (!token) {
    throw new Error('No token provided. Cannot download CSV without authorization.');
  }

  const results = await fetch('/api/v0/dashboard/tasks/csv', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to download tasks CSV failed critically.');
  }

  try {
    const blobContents = await results.blob();

    const blob = new Blob([blobContents], { type: 'text/csv' });
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objectUrl;
    a.download = `EasyRCA_Tasks_${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
      window.URL.revokeObjectURL(objectUrl);
    }, 1500);
  } catch (error) {
    throw new Error('Failed to start download for resulting CSV');
  }
};

export const getTemplates = async ({ token }) => {
  if (!token) {
    throw new Error('No token provided. Cannot fetch templates without authorization.');
  }

  const results = await fetch('/api/v0/templates', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to fetch templates failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[getTemplates, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const searchTemplates = async ({ token, query }) => {
  if (!token) {
    throw new Error('No token provided. Cannot search templates without authorization.');
  }

  if (!query) {
    throw new Error('No query provided.');
  }

  const params = encodeGetParams({ q: query });

  const results = await fetch(`/api/v0/search/templates?${params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to search templates failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[searchTemplates, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const getTemplatePreview = async ({ token, uuid }) => {
  if (!token) {
    throw new Error('No token provided. Cannot fetch template preview without authorization.');
  }

  if (!uuid) {
    throw new Error('No templateUuid provided.');
  }

  const results = await fetch(`/api/v0/template/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!results) {
    throw new Error('Request to get template preview failed critically.');
  }

  const json = await results.json();

  if (!json.ok) {
    console.error(`[getTemplatePreview, ${json.status}]: ${json.error}`);
    throw new Error(json.error);
  }

  return json.data;
};

export const deleteOrgInvite = async ({ token, member }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot delete org invitation without authorization.');
    }

    if (!member) {
      throw new Error('No member provided.');
    }

    const results = await fetch(`/api/v0/invite/${member}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      throw new Error('Request to delete org invitation failed critically.');
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[deleteOrgInvite, ${json.status}]: ${json.error}`);
      throw new Error(json.error);
    }

    return json;
  } catch (error) {
    console.error(error);

    return {
      ok: false,
      error: error.message,
    };
  }
};

export const deleteOrgMember = async ({ token, member }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot delete org member without authorization.');
    }

    if (!member) {
      throw new Error('No member provided.');
    }

    const results = await fetch(`/api/v0/user/${member}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      throw new Error('Request to delete org member failed critically.');
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[deleteOrgMember, ${json.status}]: ${json.error}`);
      throw new Error(json.error);
    }

    return json;
  } catch (error) {
    console.error(error);

    return {
      ok: false,
      error: error.message,
    };
  }
};

export const updateTree = async ({ token, treeUuid, updates }) => {
  try {
    if (!treeUuid) {
      throw new Error('No tree uuid provided. Cannot update.');
    }

    if (!token) {
      throw new Error(`No token provided. Cannot update ${treeUuid} without authorization.`);
    }

    const results = await fetch(`/api/v0/tree/${treeUuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updates),
    });

    if (!results) {
      throw new Error('Request to update tree failed critically.');
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[updateTree, ${json.status}]: ${json.error}`);
      throw new Error(json.error);
    }

    return json;
  } catch (error) {
    console.error(error);

    return {
      ok: false,
      error: error.message,
    };
  }
};

export const publishAnalysis = async ({ token, treeUuid, isPublished }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot publish analysis without authorization.');
    }

    if (!treeUuid) {
      throw new Error('No tree uuid provided. Cannot publish analysis without it.');
    }

    if (isPublished === undefined) {
      throw new Error(`Missing isPublished for ${treeUuid}. Cannot set published state without it.`);
    }

    const results = await fetch(`/api/v0/tree/${treeUuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ published: isPublished }),
    });

    if (!results || !results.ok) {
      throw new Error('Failed to set analysis published state.');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createNewWebhook = async ({ token, type = 'trees' }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot create webhook without authorization.');
    }

    const results = await fetch('/api/v0/webhook', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ webhookType: type }),
    });

    if (!results || !results.ok) {
      throw new Error('Failed to create webhook');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const changeWebhookTestMode = async ({ token, webhookUuid, enabled }) => {
  try {
    if (!token) {
      throw new Error("No token provided. Cannot change webhook's testmode without authorization.");
    } else if (!webhookUuid) {
      throw new Error('No webhook uuid provided. Cannot update testmode without a webhook uuid.');
    }

    const results = await fetch(`/api/v0/webhook/${webhookUuid}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ testMode: enabled }),
    });

    if (!results || !results.ok) {
      throw new Error(enabled ? 'Failed to enable testmode for webhook' : 'Failed to disable testmode for webhook');
    }

    const json = await results.json();

    return json;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDashboardData = async ({
  token,
  periodMonths = null,
  groupUuid = null,
  owner = null,
  facility = null,
  equipment = null,
}) => {
  if (!token || token === '') {
    throw new Error('No token provided. Cannot fetch dashboard data without authorization.');
  }

  try {
    const results = await fetch('/api/v0/dashboard/trees', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        periodMonths,
        groupUuid,
        owner,
        facility,
        equipment,
      }),
    });

    if (!results || !results.ok) {
      throw new Error('Failed to fetch dashboard data');
    }

    const json = await results.json();

    return json.data.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDashboardTasksData = async ({
  token,
  periodMonths = null,
  taskType = null,
  overdue = true,
  upcoming = null,
  gt2Weeks = true,
  lt2Weeks = null,
  groupUuid = null,
  owner = null,
  facility = null,
  equipment = null,
}) => {
  if (!token) {
    throw new Error('No token provided. Cannot fetch dashboard data without authorization.');
  }

  try {
    const results = await fetch('/api/v0/dashboard/tasks', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        periodMonths,
        taskType,
        overdue,
        gt2Weeks,
        lt2Weeks,
        upcoming,
        owner,
        groupUuid,
        facility,
        equipment,
      }),
    });

    if (!results || !results.ok) {
      throw new Error('Failed to fetch dashboard tasks data');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const blobToBase64 = url => {
  return new Promise(async (resolve, reject) => {
    // do a request to the blob uri
    const response = await fetch(url);

    // response has a method called .blob() to get the blob file
    const blob = await response.blob();

    // instantiate a file reader
    const fileReader = new FileReader();

    try {
      // read the file
      fileReader.readAsDataURL(blob);

      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const getCustomDocmosisReport = async ({ token, treeUuid, treeImage, fileFormat }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot get docx report without authorization.');
    } else if (!treeUuid) {
      throw new Error('No tree UUID provided. Cannot get docx report without it.');
    }

    if (fileFormat !== 'pdf' && fileFormat !== 'docx') {
      throw new Error(`Invalid file format: ${fileFormat}.`);
    }

    const treeImageBase64 = await blobToBase64(treeImage);
    const treeImageWithoutPrefix = treeImageBase64.split('data:image/png;base64,')[1];
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const results = await fetch(`/api/v0/tree/${treeUuid}/report`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        treeImage: `image:base64:${treeImageWithoutPrefix}`,
        timeZone,
        fileFormat,
      }),
    });

    if (!results || !results.ok) {
      throw new Error('Failed to get docmosis report');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchFacilities = async (token, query) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot search facility hierarchy without authorization.');
    } else if (!query) {
      throw new Error('No query provided. Cannot search facility hierarchy without a search query.');
    }

    const results = await fetch(`/api/v0/search/facilities?q=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results || !results.ok) {
      throw new Error('Failed to search facilities');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchEquipment = async (token, query) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot search equipment hierarchy without authorization.');
    } else if (!query) {
      throw new Error('No query provided. Cannot search equipment hierarchy without a search query.');
    }

    const results = await fetch(`/api/v0/search/equipment?q=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results || !results.ok) {
      throw new Error('Failed to search equipment');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getAllWebhooks = async token => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot fetch webhooks without authorization.');
    }

    const results = await fetch('/api/v0/webhooks', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results || !results.ok) {
      throw new Error('Failed to fetch webhooks');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getAllTags = async token => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot fetch tags without authorization.');
    }

    const results = await fetch('/api/v0/tags', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results || !results.ok) {
      throw new Error('Failed to fetch tags');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getTreeCategories = async ({ token, treeUuid }) => {
  try {
    if (!token) {
      throw new Error('No token provided. Cannot get tree categories without authorization.');
    }

    const results = await fetch(`/api/v0/tree/${treeUuid}/tags`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results || !results.ok) {
      throw new Error('Failed to fetch tree categories');
    }

    const json = await results.json();

    return json.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const assignTag = async ({ token, treeUuid, nodeUuid, tagUuid }) => {
  if (!token) {
    throw new Error(`No token provided. Cannot assign tag ${tagUuid} to node ${nodeUuid} without authorization.`);
  }

  const results = await fetch(`/api/v0/tree/${treeUuid}/tag`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      nodeUuid,
      tagUuid,
    }),
  });

  if (!results || !results.ok) {
    throw new Error('Failed to assign tag to node');
  }

  const json = await results.json();

  return json.data;
};

export const removeTag = async ({ token, treeUuid, nodeUuid, tagUuid }) => {
  if (!token) {
    throw new Error(`No token provided. Cannot remove tag ${tagUuid} from node ${nodeUuid} without authorization.`);
  }

  const results = await fetch(`/api/v0/tree/${treeUuid}/tag`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      nodeUuid,
      tagUuid,
    }),
  });

  if (!results || !results.ok) {
    throw new Error('Failed to remove tag from node');
  }

  const json = await results.json();

  return json;
};

export const sendForgotPasswordRequest = async username => {
  try {
    const results = await fetch('/forgot-password', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username }),
    });

    return results.json();
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const verifyPasswordResetRequest = async ({ token }) => {
  try {
    const results = await fetch('/verify-forgot-password', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    });

    return results.json();
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const submitNewPassword = async ({ userId, password, token }) => {
  try {
    const results = await fetch('/update-password', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId, password, token }),
    });

    return results.json();
  } catch (error) {
    return error;
  }
};

export const getStrength = (password, callback) => {
  fetch('/zxcvbn', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password }),
  })
    .then(res => res.json())
    .then(obj => callback(null, obj))
    .catch(err => {
      callback(err, null);
    });
};

let wsEndpoint;
export const getEndpoint = callback => {
  if (!wsEndpoint) {
    fetch('/ws-endpoint', {
      method: 'GET',
    })
      .then(res => {
        if (res.status === 204) {
          // production
          wsEndpoint = `wss://${window.location.hostname}/ws`;
          callback(null, wsEndpoint);
        } else if (res.status === 200) {
          // development
          res
            .text()
            .then(url => {
              wsEndpoint = url;
              callback(null, wsEndpoint);
            })
            .catch(err => callback(err, null));
        } else {
          callback(res, null);
        }
      })
      .catch(err => {
        callback(err, null);
      });
  } else {
    return callback(null, wsEndpoint);
  }
};

export const triggerCode = (email, callback) => {
  fetch('/email-verify', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  })
    .then(res => {
      if (res.status === 204) {
        callback(null, null);
      } else {
        res
          .json()
          .then(obj => callback(obj.error || true, null))
          .catch(err => {
            callback(err, null);
          });
      }
    })
    .catch(err => {
      callback(err, null);
    });
};

export const verifyCode = (email, code, callback) => {
  fetch('/code-verify', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, code }),
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(obj => callback(null, obj));
      } else {
        res.text().then(err => callback(err || true, null));
      }
    })
    .catch(err => callback(err, null));
};

export const createOrganization = (email, fullName, phone, password, companyName, members, code, callback) => {
  fetch('/create-organization', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: email,
      email,
      fullName,
      phone,
      password,
      companyName,
      members: members.toJS(),
      code,
    }),
  })
    .then(res => {
      if (res.status === 200) {
        res
          .json()
          .then(token => callback(null, token))
          .catch(err =>
            callback(
              'Unable to interpret your code into a valid login token. Please contact support@reliability.com.',
              null,
            ),
          );
      } else {
        res
          .json()
          .then(js => {
            if (js.error.includes('invalid code')) {
              callback('Your code is incorrect. Please check your code and try again.', null);
            } else {
              callback('Error processing request. Please contact support@reliability.com.', null);
            }
          })
          .catch(err => callback('Error processing request. Please contact support@reliability.com.', null));
      }
    })
    .catch(err => {
      callback('Unable to process email verification. Please contact support@reliability.com.', null);
      console.error(err);
    });
};

export const convertToMember = (inviteUUID, fullName, password, callback) => {
  fetch('/convert-to-member', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inviteUUID, fullName, password }),
  })
    .then(res => {
      if (res.status === 200) {
        res
          .json()
          .then(obj => callback(null, obj.data))
          .catch(err => callback(err, null));
      } else {
        res
          .json()
          .then(obj => callback(obj.error, null))
          .catch(err => callback(err, null));
      }
    })
    .catch(err => callback(err, null));
};

export const startPing = ws => {
  const pingMessage = JSON.stringify({ type: 'ONLINE' });
  const pingServiceId = setInterval(() => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(pingMessage);
    } else {
      clearInterval(pingServiceId);
    }
  }, 30000);
};

const getToken = async (username, password) => {
  username = username.toLowerCase();

  const res = await fetch('/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });

  if (res.status === 200) {
    const { token } = (await res.json()).data;
    return token;
  } else if (res.status === 400) {
    const msg = (await res.json()).error;
    if (msg === 'SSO_REQUIRED' || msg === 'MS_LOGIN_REQUIRED') {
      return msg;
    }
  }

  return null; // Invalid credentials or some other error
};

let _ws = null;
const openWebSocket = async url => {
  if (_ws) {
    return new Promise((resolve, refect) => {
      // Delay the resolve so that the socket readyState is OPEN by then.
      setTimeout(() => {
        resolve(_ws);
      }, 250 + 250 * Math.random());
    });
  }
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(url);
    _ws = ws;
    ws.addEventListener(
      'open',
      () => {
        resolve(ws);
      },
      { once: true },
    );
    ws.addEventListener(
      'error',
      err => {
        _ws = null;
        reject(err);
      },
      { once: true },
    );
    ws.addEventListener(
      'close',
      () => {
        _ws = null;
      },
      { once: true },
    );
  });
};

const maxRetries = 15;
const maxRetryDuration = 10000;
let retryCount = maxRetries;
export const initDispatchedWS = (credentials, dispatch, callback) => {
  credentials = credentials || fetchToken();
  const { username, password } = credentials || {
    username: '',
    password: null,
  };

  getEndpoint(async (err, url) => {
    if (err) {
      callback(err);
      return;
    }

    let ws, token, viewOnly;

    const params = matchPath(window.location.hash, {
      path: '#/report/:treeUUID/:signature?',
    });
    if (params && params.params && params.params.signature) {
      // View-only signed URL

      try {
        ws = await openWebSocket(`${url}?viewOnly=1`);
        viewOnly = true;
      } catch (e) {
        callback('Unable to establish a connection to EasyRCA.');
        return;
      }
    } else {
      // Any other case

      if (!credentials) {
        // If this is a #/tree/ URL, return an error so that the TreeEditor attempts a viewerWS
        const params = matchPath(window.location.hash, {
          path: '#/tree/:treeUUID',
        });
        if (params && params.params && params.params.treeUUID) {
          // View-only tree URL
          callback('No credentials for #/tree/ URL - attempt viewer socket.');
        } else {
          window.location.replace('/'); // Log-in screen
        }
        return;
      }

      token = username && password ? await getToken(username, password) : credentials.token;
      if (!token) {
        callback('Invalid username or password or login has expired.');
        return;
      }

      if (token === 'SSO_REQUIRED') {
        callback('Your account requires single-sign-on. Please login at the link below.');
        return;
      }

      if (token === 'MS_LOGIN_REQUIRED') {
        callback('Your account requires sign in with Microsoft. Please use the button below.');
        return;
      }

      try {
        ws = await openWebSocket(`${url}?username=${encodeURIComponent(username)}&token=${encodeURIComponent(token)}`);
        viewOnly = false;
      } catch (e) {
        //clearToken();  // Should we?
        callback('Unable to establish a connection to EasyRCA.');
        return;
      }
    }

    if (!ws) {
      // Somehow this is possible because we get Sentry errors
      callback('Unable to establish connection to EasyRCA.');
      return;
    }

    const onMessage = ({ data }) => {
      dispatch(
        Map({
          type: 'WS_MESSAGE',
          message: fromJS(JSON.parse(data)),
        }),
      );
    };

    ws.addEventListener('message', ev => {
      const obj = fromJS(JSON.parse(ev.data));
      const type = obj.get('type');

      if (type === 'HELLO') {
        // Initial HELLO with token
        const serverApiVersion = obj.get('apiVersion');
        if (apiVersion !== serverApiVersion) {
          console.log(`Client side (${apiVersion}) does not match the server side (${serverApiVersion}), reloading.`);
          window.location.reload();
        }

        startPing(ws);
        const token = obj.get('token');
        const { username } = credentials || { username: '' };

        if (token) {
          storeToken(username, token);
          // This will store the token in localstorage
          dispatch(Map({ type: 'SET_TOKEN', credentials: token }));
        }

        ws.addEventListener('message', onMessage); // All further messages handled by this
        callback(null, { ws, username });
      } else if (type === 'EXPIRED_USER') {
        // Expired free trial
        signOutAndRedirect(ws, process.env.REACT_APP_EXPIRED_REDIRECT_URL);
      } else if (type === 'BAD_TOKEN') {
        // Invalid or expired token
        signOutAndRedirect(ws);
      }
    });

    const retryOnError = (err, res) => {
      if (err) {
        if (retryCount-- > 0) {
          if (!hasToken()) {
            return;
          }
          const delayMs = (1.0 / (retryCount + 1)) * maxRetryDuration + 500 * Math.random();
          console.log(`Will retry in ${delayMs}ms, retryCount: ${retryCount}`);
          setTimeout(() => {
            console.log('Reconnecting...');
            initDispatchedWS(credentials, dispatch, retryOnError);
          }, delayMs);
          if (retryCount <= 3) {
            // Show the flash screen, we're almost out of retries
            dispatch(Map({ type: 'RECONNECTING' }));
          }
        } else {
          console.log('Unable to reconnect: out of retires.');
          callback('Unable to connect.');
          return;
        }
      } else {
        retryCount = maxRetries;
      }
    };

    if (!viewOnly) {
      ws.addEventListener(
        'close',
        () => {
          retryOnError('foo', null);
        },
        { once: true },
      );
    }

    dispatch(Map({ type: 'SET_USERNAME', username }));
    dispatch(Map({ type: 'SET_WS', ws }));

    callback(null);
  });
};

export const initViewerWS = (treeUuid, viewerDispatch) => {
  getEndpoint((err, res) => {
    if (err) {
      viewerDispatch(Map({ type: 'WS_ERROR' }));
      return;
    }

    const host = res ? res : `wss://${window.location.hostname}/ws`;
    const url = `${host}?treeUuid=${treeUuid}`;

    const ws = new WebSocket(url);

    const onError = () => viewerDispatch(Map({ type: 'WS_ERROR' }));
    const onOpen = () => ws.removeEventListener('close', onError);

    const onMessage = ev => {
      const viewOnlyTree = fromJS(JSON.parse(ev.data));
      if (viewOnlyTree.get('type') === 'ERROR') {
        viewerDispatch(Map({ type: 'WS_ERROR' }));
      } else {
        viewerDispatch(Map({ type: 'SYNC', viewOnlyTree }));
      }

      ws.close();
    };

    ws.addEventListener('close', onError, { once: true });
    ws.addEventListener('open', onOpen, { once: true });
    ws.addEventListener('message', onMessage, { once: true });
  });
};

// export const uploadCSVFile = (url, formData, callback) => {
//   // Set up our HTTP request
//   var xhr = new XMLHttpRequest();

//   // Setup our listener to process compeleted requests
//   xhr.onloadend = function () {
//     // Process our return data
//     if (xhr.status !== 200) {
//       // What do when the request is successful
//       console.log('error', xhr);
//       let data = JSON.parse(xhr.responseText);
//       callback(data);
//       window.alert(data.message);
//     } else {
//       // What to do when the request has failed
//       console.log('success', JSON.parse(xhr.responseText));
//       let data = JSON.parse(xhr.responseText);
//       callback(data);
//       window.alert(data.message);
//     }
//   };

//   // Create and send a GET request
//   // The first argument is the post type (GET, POST, PUT, DELETE, etc.)
//   // The second argument is the endpoint URL
//   xhr.open('POST', url, formData);
//   xhr.send(formData);
// };
